import React from 'react';
import TierListImage from '../images/SatyrnTierList.png'
import './Research.css';

const About = () => {
  return (
    <div className="about-page">
        <img
          src={TierListImage}
          width="100%"
          alt="Logo"
          className="about-logo"
        />
        <div className="about-container">
          <h2>VPNs I have personally tested:</h2>
          <p>
            Here is the list of VPNs I have personally tested:
          </p>
          <p>
            <i>
              ExpressVPN, NordVPN, Surfshark, BulletVPN, CyberGhost, Private Internet Access (PIA), ProtonVPN, VyprVPN, IPVanish, Windscribe, Mullvad, TunnelBear, Hide.Me, ZenMate, 
              Hotspot Shield, PureVPN, IvacyVPN, HideMyAss (HMA), PrivateVPN, TorGuard, StrongVPN, WeVPN, AirVPN, Norton Secure VPN, McAfee Safe Connect VPN, AtlasVPN, CactusVPN, 
              VPN Unlimited, ZoogVPN, Betternet, OperaVPN, Perfect-PrivacyVPN, AstrillVPN, HolaVPN, ibVPN (Invisible Browsing VPN), VPN.ac, SlickVPN, OVPN, PandaVPN, SwitchVPN, 
              PersonalVPN, AzireVPN, Seed4.Me VPN, VPNArea, Encrypt.me, UltraVPN, and FastestVPN.</i>
          </p>
          <p>
            I could have missed one; however, to the best of my knowledge, this is all the VPNs I have tested. There are some listed that I have not placed on the tier list, and this 
            is because I need to test the VPN to be able to set the VPN on the tier list correctly.
          </p>
          <h2>How many years of VPN testing/research have you done?</h2>
          <p>
          This website is the result of five years of VPN research and testing. I have done everything from testing the VPN (7-14 days each) to studying the VPNs past dramas, leaks, 
          and privacy practices. I have also taken a lot of time reading and watching reviews about each VPN and learning how each VPN promotes itself through videos, ads, blogs, 
          forums, etc. The website was fast, roughly 3-4 hours of programming, but the effort I put into the VPNs to ensure I had as much information as I possibly needed to have a 
          well-constructed tier list took me a long time.
          </p>
          <h2>How long do I test the VPN for?</h2>
          <p>
            This is an excellent question I have been asked in the past. Let me elaborate a little on my testings. Usually, I start by selecting the VPN I wish to retest or try out. 
            The tests I run the VPN through are pretty long; however, I never base a VPN review on speed, as I am adamant about speed tests being very subjective.
          </p>
          <p>
            When I finally select the VPN, I will download and test/try it out. As my daily driver, I will use the VPN for 7-14 days. I use it for anything from browsing the web to 
            downloading games, watching movies/TV Shows, programming, uploading data to the web, playing video games, and whatever else you can think of that you do daily. During 
            the process, I will mess around with VPN settings, test its functions, see if it works with other settings it should adequately work with, check if the settings 
            tooltips/information sections make sense, and how well set up the app is and a multitude of other random things, there are a few of these entirely subjective things. I 
            do not ever base my test on the VPN settings, how the app looks/feels, or anything similar, as I find that varies quite drastically from person to person. I test the VPN 
            on how well it functions with other apps, how well the settings work with different settings, that it should work with unless otherwise specified, and other more 
            objective matters.
          </p>
          <p>
            Once I have done the 7-14 days of testing, I will then place the VPN on the tier list and continue to the following VPN in the list.
          </p>
    </div>
    </div>
  );
};

export default About;
