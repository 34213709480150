import React from 'react';
import TierListImage from '../images/SatyrnTierList.png'
import './VPNList.css';

const TierList = () => {
  return (
    <div className="tier-list">
        <img
          src={TierListImage}
          width="100%"
          alt="Logo"
          className="about2-logo"
        />
        <div className="top-container">
        <div className="satyrn-container">
        <h3 className="main-heading">
          Why do we believe in our product?
        </h3>
        <p>
        - <b>Independent Evaluation:</b> The researcher’s tier list is based on thorough, unbiased evaluations rather than financial incentives.<br></br>
        - <b>No Paid Promotions:</b> There are no paid reviews or affiliate links influencing the rankings.<br></br>
        - <b>Transparency:</b> The data researcher provides clear, honest assessments of each VPN’s features and performance.<br></br>
        - <b>Free and Accessible:</b> All information and resources are freely available to the public, ensuring openness and accessibility.<br></br>
        - <b>Dedication to Quality:</b> The tier list is created out of genuine interest in providing valuable and accurate information, not for profit.<br></br>
        </p>
        <br></br>
        <h6>
          Disclaimer: The Tier List might be missing some VPNs in the Budget, Streaming, and Privacy tier lists; however, we will work hard on making sure everything is updated in a 
          timely manner!
        </h6>
      </div>
      <div className="satyrn-container">
      <h3 className='main-heading'>Our Favorite Products:
      </h3>
        <p>
            Post/Message Remover: <a href='https://redact.dev/' target='_blank' rel="noopener noreferrer">Redact</a>
          <br />
        </p>
        <p>
          Privacy Browser: <a href='https://brave.com/' target='_blank' rel="noopener noreferrer">Brave</a>, <a href='https://www.mozilla.org/en-US/firefox/' target='_blank' rel="noopener noreferrer">Firefox</a>, & <a href='https://www.torproject.org/' target='_blank' rel="noopener noreferrer">Tor Browser</a>
          <br />
        </p>
        <p>
          Privacy Email: <a href='https://tuta.com/' target='_blank' rel="noopener noreferrer">Tuta/Tutanota</a>, <a href='https://proton.me/mail' target='_blank' rel="noopener noreferrer">ProtonMail</a>, & <a href='https://www.startmail.com/' target='_blank' rel="noopener noreferrer">StartMail</a>
          <br />
        </p>
        <p>
          Password Managers: <a href='https://bitwarden.com/' target='_blank' rel="noopener noreferrer">Bitwarden</a> & <a href='https://keepassxc.org/' target='_blank' rel="noopener noreferrer">KeePassXC</a>
          <br />
        </p>
        <p>
          Cloud Storage: <a href='https://filen.io/' target='_blank' rel="noopener noreferrer">Filen.IO</a>
          <br />
        </p>
        <p>
          Encrypted DNS: <a href='https://quad9.net/' target='_blank' rel="noopener noreferrer">Quad9</a> & <a href='https://adguard-dns.io/en/welcome.html' target='_blank' rel="noopener noreferrer">AdGuard</a>
          <br />
        </p>
      </div>
      </div>
      <h3>VPN List (In order from our "Default" Tier List)</h3>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://mullvad.net/" target="_blank" rel="noopener noreferrer">Mullvad</a></h3>
        <p className="left-align">Known for its strong privacy focus and anonymity features, Mullvad offers a no-logs policy, accepts anonymous payments, and provides excellent 
          security and performance.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://ivpn.net/" target="_blank" rel="noopener noreferrer">IVPN</a></h3>
        <p className="left-align">IVPN emphasizes privacy and security, offering strong encryption, a no-logs policy, and additional features like a multi-hop VPN for enhanced 
          anonymity.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://proton.me/" target="_blank" rel="noopener noreferrer">ProtonVPN</a></h3>
        <p className="left-align">Developed by the team behind ProtonMail, it provides high-security standards, a strict no-logs policy, and is known for its reliable free tier 
          with decent speeds.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://windscribe.com/" target="_blank" rel="noopener noreferrer">Windscribe</a></h3>
        <p className="left-align">Offers a good balance of security, privacy, and user-friendly features, including a generous free plan, ad-blocking, and firewall features.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://privatevpn.com/" target="_blank" rel="noopener noreferrer">PrivateVPN</a></h3>
        <p className="left-align">Provides strong encryption, a no-logs policy, and good performance. It’s known for its ease of use and ability to bypass geo-restrictions.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://airvpn.org/" target="_blank" rel="noopener noreferrer">AirVPN</a></h3>
        <p className="left-align">Focuses on privacy and transparency, with strong encryption and a strict no-logs policy. It is also known for its advanced configuration options.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://ovpn.com/" target="_blank" rel="noopener noreferrer">OVPN</a></h3>
        <p className="left-align">Offers strong security with a focus on privacy. It has a transparent no-logs policy and is known for its straightforward user experience.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://azirevpn.com/" target="_blank" rel="noopener noreferrer">AzireVPN</a></h3>
        <p className="left-align">A Swedish VPN that emphasizes privacy and security with a no-logs policy and solid performance.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://hide.me/" target="_blank" rel="noopener noreferrer">Hide.Me</a></h3>
        <p className="left-align">Provides a good balance of security and performance, with a no-logs policy and features like a free plan and customizable options.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://vyprvpn.com/" target="_blank" rel="noopener noreferrer">VyprVPN</a></h3>
        <p className="left-align">Offers a proprietary Chameleon protocol to bypass censorship, with strong security features and a no-logs policy, but sometimes criticized for 
          performance and pricing.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://vpnarea.com/" target="_blank" rel="noopener noreferrer">VPNArea</a></h3>
        <p className="left-align">Provides good security features, a no-logs policy, and decent speeds, although it might not be as user-friendly as some competitors.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://cyberghostvpn.com/" target="_blank" rel="noopener noreferrer">CyberGhost</a></h3>
        <p className="left-align">Known for its user-friendly interface and large server network, CyberGhost provides good security and privacy features, though it has faced some 
          past criticisms.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://perfect-privacy.com/" target="_blank" rel="noopener noreferrer">Perfect-Privacy</a></h3>
        <p className="left-align">Offers advanced privacy features and strong encryption, but may be more complex to use and has a higher price point.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://expressvpn.com/" target="_blank" rel="noopener noreferrer">ExpressVPN</a></h3>
        <p className="left-align">Widely known for its high performance, ease of use, and strong security features. It is considered reliable but can be more expensive.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://privateinternetaccess.com/" target="_blank" rel="noopener noreferrer">PIA (Private Internet Access)</a></h3>
        <p className="left-align">Known for its robust security features, no-logs policy, and affordability. It offers a large server network and good customization options.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://tunnelbear.com/" target="_blank" rel="noopener noreferrer">TunnelBear</a></h3>
        <p className="left-align">Provides a user-friendly interface with a focus on simplicity and ease of use. It has a free plan with limited data and a commitment to privacy.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://personalvpn.com/" target="_blank" rel="noopener noreferrer">PersonalVPN</a></h3>
        <p className="left-align">Offers basic security features with a focus on ease of use and affordability. It is less known but provides a decent VPN experience.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://bulletvpn.com/" target="_blank" rel="noopener noreferrer">BulletVPN</a></h3>
        <p className="left-align">Provides solid security features with decent performance, though it may not stand out compared to higher-tier VPNs.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://astrill.com/" target="_blank" rel="noopener noreferrer">AstrillVPN</a></h3>
        <p className="left-align">Known for its high-speed performance and strong security features, but it can be more expensive and less transparent about its privacy policies.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://nordvpn.com/" target="_blank" rel="noopener noreferrer">NordVPN</a></h3>
        <p className="left-align">A popular VPN with strong security features, a large server network, and good performance. It has faced some concerns over past security incidents 
          but remains highly used.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://surfshark.com/" target="_blank" rel="noopener noreferrer">SurfShark</a></h3>
        <p className="left-align">Offers good value for money with strong security features and an unlimited number of simultaneous connections. It has good performance and user-friendly 
          apps.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://torguard.net/" target="_blank" rel="noopener noreferrer">TorGuard</a></h3>
        <p className="left-align">Known for its strong privacy features and advanced customization options, TorGuard offers good security but might be more complex for beginners.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://vpn.ac/" target="_blank" rel="noopener noreferrer">VPN.ac</a></h3>
        <p className="left-align">Provides strong encryption and a focus on privacy, with good performance and transparency, though it might not be as well-known as some competitors.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://cactusvpn.com/" target="_blank" rel="noopener noreferrer">CactusVPN</a></h3>
        <p className="left-align">Offers basic security features with decent performance, but may lack some advanced features compared to higher-tier VPNs.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://strongvpn.com/" target="_blank" rel="noopener noreferrer">StrongVPN</a></h3>
        <p className="left-align">Provides solid security and privacy features with good performance, but it may not be as feature-rich as some higher-tier options.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://adguard-vpn.com/" target="_blank" rel="noopener noreferrer">AdGuard</a></h3>
        <p className="left-align">Focuses on ad-blocking and privacy protection, with a VPN service that offers decent security and performance.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://vpnunlimited.com/" target="_blank" rel="noopener noreferrer">VPNUnlimited</a></h3>
        <p className="left-align">Offers good security features and a large server network, though it may not stand out in terms of performance or advanced features.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://hotspotshield.com/" target="_blank" rel="noopener noreferrer">Hotspot Shield</a></h3>
        <p className="left-align">Known for its fast speeds and ease of use, Hotspot Shield offers a freemium model with a paid version providing better security and performance.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://zoogvpn.com/" target="_blank" rel="noopener noreferrer">ZoogVPN</a></h3>
        <p className="left-align">Provides basic security features with a focus on affordability. It offers a free plan with limited features and a paid plan with better performance.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://bravevpn.com/" target="_blank" rel="noopener noreferrer">BraveVPN</a></h3>
        <p className="left-align">A newer entrant with a focus on integrating privacy features into the Brave browser. It has basic VPN capabilities but lacks comprehensive features 
          compared to established VPNs.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://www.avast.com/" target="_blank" rel="noopener noreferrer">AvastVPN</a></h3>
        <p className="left-align">Part of the Avast antivirus suite, it offers basic VPN services with an emphasis on integrating security features. Performance and privacy may not 
          be as strong as dedicated VPN providers.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://ivacyvpn.com/" target="_blank" rel="noopener noreferrer">IvacyVPN</a></h3>
        <p className="left-align">Provides a good range of features and decent performance, but has faced criticism for transparency and user experience.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://hidemyass.com/" target="_blank" rel="noopener noreferrer">HMA (HideMyAss)</a></h3>
        <p className="left-align">Known for its large server network, but it has a controversial past regarding privacy policies and logging practices.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://www.hideipvpn.com/" target="_blank" rel="noopener noreferrer">HideIPVPN</a></h3>
        <p className="left-align">Offers basic VPN services with a focus on affordability, but lacks advanced features and transparency.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://www.fastestvpn.com/" target="_blank" rel="noopener noreferrer">FaststVPN</a></h3>
        <p className="left-align">Claims to offer fast speeds and basic security features, but it might not be as reliable or feature-rich as higher-tier options.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://www.holavpn.org/" target="_blank" rel="noopener noreferrer">HolaVPN</a></h3>
        <p className="left-align">Known for its free service, but it has faced criticism for security and privacy issues due to its peer-to-peer network model.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://www.ipvanish.com/" target="_blank" rel="noopener noreferrer">IPVanish</a></h3>
        <p className="left-align">Offers good performance and security, but has faced issues with transparency and logging practices in the past.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align"><a href="https://www.purevpn.com/" target="_blank" rel="noopener noreferrer">PureVPN</a></h3>
        <p className="left-align">Provides a range of features and a large server network, but has faced concerns regarding its logging policies and privacy practices.</p>
      </div>
    </div>
  );
};

export default TierList;
