import React from 'react';
import TierListImage from '../images/SatyrnTierList.png'
import './TierList.css';

const TierList = () => {
  const defaultTiers = ['S', 'A', 'B', 'C', 'F'];

  const defaultTiersData = {
    S: ['Mullvad', 'IVPN', 'ProtonVPN', 'Windscribe'],
    A: ['PrivateVPN', 'AirVPN', 'OVPN', 'AzireVPN', 'Hide.Me'],
    B: ['VyprVPN', 'VPNArea', 'Cyberghost', 'Perfect-Privacy', 'ExpressVPN', 'PIA', 'TunnelBear', 'PersonalVPN', 'BulletVPN', 'AstrillVPN'],
    C: ['NordVPN', 'SurfShark', 'TorGuard', 'VPN.ac', 'CactusVPN', 'StrongVPN', 'AdGuard', 'VPN Unlimited', 'Hotspot Shield', 'ZoogVPN'],
    F: ['BraveVPN', 'AvastVPN', 'IvacyVPN', 'HideMyAss', 'HideIPVPN', 'FastestVPN', 'HolaVPN', 'IPVanish', 'PureVPN'],
  };

  const vpnLinks = {
    'TorGuard': 'https://torguard.net/',
    'Seed4.me': 'https://seed4.me/pages/home',
    'FastestVPN': 'https://fastestvpn.com/',
    'Perfect-Privacy': 'https://www.perfect-privacy.com/en/',
    'BulletVPN': 'https://bulletvpn.com/home.html',
    'VPN.ac': 'https://vpn.ac/',
    'VPNArea': 'https://vpnarea.com/',
    'StrongVPN': 'https://strongvpn.com/',
    'AstrillVPN': 'https://www.astrill.com/',
    'HideMyAss': 'https://www.hidemyass.com/en-us/index',
    'TunnelBear': 'https://www.tunnelbear.com/',
    'IVPN': 'https://www.ivpn.net/en/',
    'Hotspot Shield': 'https://www.hotspotshield.com/',
    'ZoogVPN': 'https://zoogvpn.com/',
    'Windscribe': 'https://windscribe.com/',
    'PrivateVPN': 'https://privatevpn.com/',
    'VPN Unlimited': 'https://www.vpnunlimited.com/',
    'SurfShark': 'https://surfshark.com/',
    'NordVPN': 'https://nordvpn.com/?utm_term&utm_content',
    'Hide.Me': 'https://hide.me/en/',
    'PersonalVPN': 'https://www.personalvpn.com/',
    'ProtonVPN': 'https://protonvpn.com/',
    'PIA': 'https://www.privateinternetaccess.com/',
    'VyprVPN': 'https://www.vyprvpn.com/',
    'ExpressVPN': 'https://www.expressvpn.com/',
    'IvacyVPN': 'https://www.ivacy.com/',
    'CactusVPN': 'https://www.cactusvpn.com/',
    'Mullvad': 'https://mullvad.net/en',
    'PureVPN': 'https://www.purevpn.com/',
    'Cyberghost': 'https://www.cyberghostvpn.com/en_US/',
    'AdGuard': 'https://adguard-vpn.com/en/welcome.html',
    'AirVPN': 'https://airvpn.org/',
    'AzireVPN': 'https://www.azirevpn.com/',
    'IPVanish': 'https://www.ipvanish.com/',
    'OVPN': 'https://www.ovpn.com/en',
    'VPNUnlimited': 'https://www.vpnunlimited.com/',
    'HideIPVPN': 'https://www.hideipvpn.com/',
    'HolaVPN': 'https://hola.org/',
    'BraveVPN': 'https://brave.com/firewall-vpn/',
    'AvastVPN': 'https://www.avast.com/en-us/secureline-vpn#pc',
  };

  return (
    <div className="tier-list">
        <img
          src={TierListImage}
          width="100%"
          alt="Logo"
          className="about2-logo"
        />
        <div className="top-container">
        <div className="satyrn-container">
        <h3 className="main-heading">
          Why do we believe in our product?
        </h3>
        <p>
        - <b>Independent Evaluation:</b> The researcher’s tier list is based on thorough, unbiased evaluations rather than financial incentives.<br></br>
        - <b>No Paid Promotions:</b> There are no paid reviews or affiliate links influencing the rankings.<br></br>
        - <b>Transparency:</b> The data researcher provides clear, honest assessments of each VPN’s features and performance.<br></br>
        - <b>Free and Accessible:</b> All information and resources are freely available to the public, ensuring openness and accessibility.<br></br>
        - <b>Dedication to Quality:</b> The tier list is created out of genuine interest in providing valuable and accurate information, not for profit.<br></br>
        </p>
        <br></br>
        <h6>
          Disclaimer: The Tier List might be missing some VPNs in the Budget, Streaming, and Privacy tier lists; however, we will work hard on making sure everything is updated in a 
          timely manner!
        </h6>
      </div>
      <div className="satyrn-container">
      <h3 className='main-heading'>Our Favorite Products:
      </h3>
        <p>
            Post/Message Remover: <a href='https://redact.dev/' target='_blank' rel="noopener noreferrer">Redact</a>
          <br />
        </p>
        <p>
          Privacy Browser: <a href='https://brave.com/' target='_blank' rel="noopener noreferrer">Brave</a>, <a href='https://www.mozilla.org/en-US/firefox/' target='_blank' rel="noopener noreferrer">Firefox</a>, & <a href='https://www.torproject.org/' target='_blank' rel="noopener noreferrer">Tor Browser</a>
          <br />
        </p>
        <p>
          Privacy Email: <a href='https://tuta.com/' target='_blank' rel="noopener noreferrer">Tuta/Tutanota</a>, <a href='https://proton.me/mail' target='_blank' rel="noopener noreferrer">ProtonMail</a>, & <a href='https://www.startmail.com/' target='_blank' rel="noopener noreferrer">StartMail</a>
          <br />
        </p>
        <p>
          Password Managers: <a href='https://bitwarden.com/' target='_blank' rel="noopener noreferrer">Bitwarden</a> & <a href='https://keepassxc.org/' target='_blank' rel="noopener noreferrer">KeePassXC</a>
          <br />
        </p>
        <p>
          Cloud Storage: <a href='https://filen.io/' target='_blank' rel="noopener noreferrer">Filen.IO</a>
          <br />
        </p>
        <p>
          Encrypted DNS: <a href='https://quad9.net/' target='_blank' rel="noopener noreferrer">Quad9</a> & <a href='https://adguard-dns.io/en/welcome.html' target='_blank' rel="noopener noreferrer">AdGuard</a>
          <br />
        </p>
      </div>
      </div>
      <h3>VPN Top Choices</h3>
      <div className="top-vpns-container">
        <h3 className="left-align">1) <a href="https://mullvad.net/" target="_blank" rel="noopener noreferrer">Mullvad</a></h3>
        <p className="left-align">Great VPN for privacy-minded individuals.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align">2) <a href="https://ivpn.net/" target="_blank" rel="noopener noreferrer">IVPN</a></h3>
        <p className="left-align">Great VPN for privacy-minded individuals, with a spectacular GUI.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align">3) <a href="https://proton.me/" target="_blank" rel="noopener noreferrer">ProtonVPN</a></h3>
        <p className="left-align">Perfect VPN for those who want a VPN that has everything all-in-one.</p>
      </div>
      <div className="top-vpns-container">
        <h3 className="left-align">4) <a href="https://windscribe.com/" target="_blank" rel="noopener noreferrer">Windscribe</a></h3>
        <p className="left-align">Good streaming VPN with a great UI.</p>
      </div>
      <div className="tierlist-container">
      <div className="satyrn-container">
        <h3>Satyrn VPN Default Tier List 2024 v1.0</h3>
        <p>
          The VPNs are ordered based on overall performance: minus speed, as that is too subjective.
        </p>
      </div>
      <div className="tiers-container">
        {defaultTiers.map((tier, index) => (
          <div className="tier" key={index}>
            <h2>{tier}</h2>
            <div className="items">
              {defaultTiersData[tier].map((vpn, vpnIndex) => (
                <div className="vpn-item" key={vpnIndex}>
                  <a href={vpnLinks[vpn]} target="_blank" rel="noopener noreferrer" className="vpn-link">
                    {vpn}
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="info-container">
      <h3>How we gathered our data?</h3>
      <p>
        The Tier List data is gathered purely based on one individual's experience with the above VPNs. There is <b>NEVER</b> any data listed that the tester has not individually 
        tested!
      </p>
      </div>
      <div className="info-container">
      <h3>Our criteria?</h3>
      <p>
        This category was essentially just based on overall performance, and there were only minor adjustments based on VPNs' respective reputations.
      </p>
      </div>
      </div>
      <div className="updates-container">
        <h2>Updates:</h2>
        <h3>Satyrn VPN Tier List - 8/4/2024 @18:30</h3>
        <p>
          <ul>
            1) Changed it so only the <b>"Default"</b> tier list appears on the <i>home</i> page. If you want specific tier lists, go to the header under <b>"Tier Lists"</b> to  view 
            more.
          </ul>
          <ul>
            2) Also, we have finally added to <b>"Our Favorite Products"</b> section, which will appear on all tier list pages.
          </ul>
        </p>
        <h3>Satyrn VPN Tier List - 8/3/2024 @19:40:</h3>
        <p>
          Some minor updates to the tier list.
          <ul>
            1) Finished the About Me page
          </ul>
          <ul>
            2) Made a Research page - highlights some of my research and how much time I took with the tier list
          </ul>
          <ul>
            3) Added a few more VPNs to the "Default" tier list.
          </ul>
        </p>
        <h3>Satyrn VPN Tier List - 8/03/2024 @14:00:</h3>
        <p>
          This is the <i>first</i> version of the Tier List publicly posted.
          <ul>
            1) Initial release of the <b>"Default"</b> Tier List.
          </ul>
          <ul>
            2) Initial release of the <b>"Budget"</b> Tier List.
          </ul>
          <ul>
            3) Initial release of the <b>"Streaming"</b> Tier List.
          </ul>
          <ul>
            4) Initial release of the <b>"Privacy"</b> Tier List.
          </ul>
        <p>
          The goal is to have as many tier lists as possible for multiple use cases.
        </p>
        </p>
      </div>
    </div>
  );
};

export default TierList;
