import React, {  } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import TierList from './components/TierList';
import About from './components/About';
import PrivacyPolicy from './components/PrivacyPolicy';
import Research from './components/Research';
import PrivacyTierList from './components/PrivacyTierList';
import BudgetTierList from './components/BudgetTierList';
import StreamingTierList from './components/StreamingTierList';
import VPNList from './components/VPNList';
import './App.css';

const App = () => {return (
    <Router>
      <div className="app">
        <Header />
        <div className="content-wrapper">
          <Routes>
            <Route path="/" element={<TierList />} />
            <Route path="/about" element={<About />} />
            <Route path="/research" element={<Research />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/privacytierlist" element={<PrivacyTierList />} />
            <Route path="/budgettierlist" element={<BudgetTierList />} />
            <Route path="/streamingtierlist" element={<StreamingTierList />} />
            <Route path="/VPNList" element={<VPNList />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;