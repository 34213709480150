import React from 'react';
import TierListImage from '../images/SatyrnTierList.png'
import './TierList.css';

const TierList = () => {
  const budgetTiers = ['S', 'A'];

  const budgetTiersData = {
    S: ['HolaVPN', 'Seed4.me', 'FastestVPN', 'Mullvad', 'AzireVPN', 'IVPN'],
    A: ['AirVPN', 'Windscribe', 'VPN.ac'],
  };

  const vpnLinks = {
    'TorGuard': 'https://torguard.net/',
    'Seed4.me': 'https://seed4.me/pages/home',
    'FastestVPN': 'https://fastestvpn.com/',
    'Perfect-Privacy': 'https://www.perfect-privacy.com/en/',
    'BulletVPN': 'https://bulletvpn.com/home.html',
    'VPN.ac': 'https://vpn.ac/',
    'VPNArea': 'https://vpnarea.com/',
    'StrongVPN': 'https://strongvpn.com/',
    'AstrillVPN': 'https://www.astrill.com/',
    'HideMyAss': 'https://www.hidemyass.com/en-us/index',
    'TunnelBear': 'https://www.tunnelbear.com/',
    'IVPN': 'https://www.ivpn.net/en/',
    'Hotspot Shield': 'https://www.hotspotshield.com/',
    'ZoogVPN': 'https://zoogvpn.com/',
    'Windscribe': 'https://windscribe.com/',
    'PrivateVPN': 'https://privatevpn.com/',
    'VPN Unlimited': 'https://www.vpnunlimited.com/',
    'SurfShark': 'https://surfshark.com/',
    'NordVPN': 'https://nordvpn.com/?utm_term&utm_content',
    'Hide.Me': 'https://hide.me/en/',
    'PersonalVPN': 'https://www.personalvpn.com/',
    'ProtonVPN': 'https://protonvpn.com/',
    'PIA': 'https://www.privateinternetaccess.com/',
    'VyprVPN': 'https://www.vyprvpn.com/',
    'ExpressVPN': 'https://www.expressvpn.com/',
    'IvacyVPN': 'https://www.ivacy.com/',
    'CactusVPN': 'https://www.cactusvpn.com/',
    'Mullvad': 'https://mullvad.net/en',
    'PureVPN': 'https://www.purevpn.com/',
    'Cyberghost': 'https://www.cyberghostvpn.com/en_US/',
    'AdGuard': 'https://adguard-vpn.com/en/welcome.html',
    'AirVPN': 'https://airvpn.org/',
    'AzireVPN': 'https://www.azirevpn.com/',
    'IPVanish': 'https://www.ipvanish.com/',
    'OVPN': 'https://www.ovpn.com/en',
    'VPNUnlimited': 'https://www.vpnunlimited.com/',
    'HideIPVPN': 'https://www.hideipvpn.com/',
    'HolaVPN': 'https://hola.org/',
    'BraveVPN': 'https://brave.com/firewall-vpn/',
    'AvastVPN': 'https://www.avast.com/en-us/secureline-vpn#pc',
  };

  return (
    <div className="tier-list">
        <img
          src={TierListImage}
          width="100%"
          alt="Logo"
          className="about2-logo"
        />
        <div className="top-container">
        <div className="satyrn-container">
        <h3 className="main-heading">
          Why do we believe in our product?
        </h3>
        <p>
        - <b>Independent Evaluation:</b> The researcher’s tier list is based on thorough, unbiased evaluations rather than financial incentives.<br></br>
        - <b>No Paid Promotions:</b> There are no paid reviews or affiliate links influencing the rankings.<br></br>
        - <b>Transparency:</b> The data researcher provides clear, honest assessments of each VPN’s features and performance.<br></br>
        - <b>Free and Accessible:</b> All information and resources are freely available to the public, ensuring openness and accessibility.<br></br>
        - <b>Dedication to Quality:</b> The tier list is created out of genuine interest in providing valuable and accurate information, not for profit.<br></br>
        </p>
        <br></br>
        <h6>
          Disclaimer: The Tier List might be missing some VPNs in the Budget, Streaming, and Privacy tier lists; however, we will work hard on making sure everything is updated in a 
          timely manner!
        </h6>
      </div>
      <div className="satyrn-container">
      <h3 className='main-heading'>Our Favorite Products:
      </h3>
        <p>
            Post/Message Remover: <a href='https://redact.dev/' target='_blank' rel="noopener noreferrer">Redact</a>
          <br />
        </p>
        <p>
          Privacy Browser: <a href='https://brave.com/' target='_blank' rel="noopener noreferrer">Brave</a>, <a href='https://www.mozilla.org/en-US/firefox/' target='_blank' rel="noopener noreferrer">Firefox</a>, & <a href='https://www.torproject.org/' target='_blank' rel="noopener noreferrer">Tor Browser</a>
          <br />
        </p>
        <p>
          Privacy Email: <a href='https://tuta.com/' target='_blank' rel="noopener noreferrer">Tuta/Tutanota</a>, <a href='https://proton.me/mail' target='_blank' rel="noopener noreferrer">ProtonMail</a>, & <a href='https://www.startmail.com/' target='_blank' rel="noopener noreferrer">StartMail</a>
          <br />
        </p>
        <p>
          Password Managers: <a href='https://bitwarden.com/' target='_blank' rel="noopener noreferrer">Bitwarden</a> & <a href='https://keepassxc.org/' target='_blank' rel="noopener noreferrer">KeePassXC</a>
          <br />
        </p>
        <p>
          Cloud Storage: <a href='https://filen.io/' target='_blank' rel="noopener noreferrer">Filen.IO</a>
          <br />
        </p>
        <p>
          Encrypted DNS: <a href='https://quad9.net/' target='_blank' rel="noopener noreferrer">Quad9</a> & <a href='https://adguard-dns.io/en/welcome.html' target='_blank' rel="noopener noreferrer">AdGuard</a>
          <br />
        </p>
      </div>
      </div>
      <div className="tierlist-container">
      <div className="satyrn-container">
        <h3>Satyrn VPN Budget Tier List 2024 v1.0</h3>
        <p>
          The VPNs are ordered based entirely on monthly cost, which is based on USD.
        </p>
      </div>
      <div className="tiers-container">
        {budgetTiers.map((tier, index) => (
          <div className="tier" key={index}>
            <h2>{tier}</h2>
            <div className="items">
              {budgetTiersData[tier].map((vpn, vpnIndex) => (
                <div className="vpn-item" key={vpnIndex}>
                  <a href={vpnLinks[vpn]} target="_blank" rel="noopener noreferrer" className="vpn-link">
                    {vpn}
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="info-container">
      <h3>How we gathered our data?</h3>
      <p>
        The Tier List data is gathered purely based on one individual's experience with the above VPNs. There is <b>NEVER</b> any data listed that the tester has not individually tested!
      </p>
      </div>
      <div className="info-container">
      <h3>Our criteria?</h3>
      <p>
        This category is entirely based on value at a p/month rate - no other considerations.
      </p>
      <p>
      S tier is any VPN $6 USD or less
      </p>
      <p>
        A tier is any VPN $9 USD or less
      </p>
      </div>
      </div>
    </div>
  );
};

export default TierList;
