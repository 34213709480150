import React from 'react';
import TierListImage from '../images/SatyrnTierList.png'
import './About.css';

const About = () => {
  return (
    <div className="about-page">
        <img
          src={TierListImage}
          width="100%"
          alt="Logo"
          className="about-logo"
        />
        <div className="about-container">
          <h2>Who am I?</h2>
          <p>
            I am an individual who was introduced to the internet at the age of 7. The first thing I ever signed up for was Yahoo, which later led to Myspace. Yes, I started my online 
            journey on Myspace… At the time, I was so young that I knew nothing about the intrusive actions companies like Yahoo and Myspace posed, and even worse, Google and Facebook. 
            All I knew was that I was in a new territory with new opportunities, and I went in without knowing the plague it could cause. When I got older, Facebook became the new 
            go-to social service of choice and quickly took Myspace out… being the typical young child I was, I signed up for it, and I can admit I still have an account for family 
            needs. This led me into a more bottomless pit of online privacy invasions I never knew would be possible.
          </p>
          <p>
            Fast forward; I am now 24 years old. I have a deep passion for online privacy and a keen eye for privacy policies. This is why this site has become a thing; it is my form 
            of education. I wish to use this site to promote the benefits of VPNs and how they can help your online privacy; however, VPNs are only one tool in the toolbag - DO NOT use
            VPNs think it will be the end-all-be-all.
          </p>
          <h2>When did I start getting into online privacy?</h2>
          <p>
            I started getting into online privacy around 2015. It started simply: I got a nasty letter from my ISP for downloading pirated content. So, instead of not doing it 
            anymore, I decided to fight back and promote it! Not literally, I do not actively promote pirating content; however, this is the reason I got into VPNs and, overall, 
            why I got into online privacy.
          </p>
          <h2>Why the Tier List?</h2>
          <p>
            This tier list is inspired by all of the content creators I watch/used to watch in the past (Tom Spark, Techlore, Privacy Protector, The Emotional Hacker, and many more). 
            I wanted to create my own little following, promote what I believe in, and hopefully bring light about privacy to those who may not know better.
          </p>
    </div>
    </div>
  );
};

export default About;
