import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header-title">
        <Link to="/">Satyrn VPN Tier List</Link>
      </div>
      <div className="header-links">
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/research">Research</Link>
        <div className="header-dropdown">
          Tier Lists
          <div className="header-dropdown-content">
          <a href="/vpnlist">
              VPN Comparison
            </a>
            <a href="/privacytierlist">
              Privacy Tier List
            </a>
            <a href="/budgettierlist">
              Budget Tier List
            </a>
            <a href="/streamingtierlist">
              Streaming Tier List
            </a>
          </div>
        </div>
        <div className="header-dropdown">
          Policies
          <div className="header-dropdown-content">
            <a href="/privacy-policy">
              Privacy Policy
            </a>
          </div>
        </div>
        <div className="header-dropdown">
          Socials
          <div className="header-dropdown-content">
          <a href="https://www.reddit.com/r/SatyrnVPNTierList/" target="_blank" rel="noopener noreferrer">
              Reddit
            </a>
            <a href="https://discord.gg/" target="_blank" rel="noopener noreferrer">
              Discord
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
